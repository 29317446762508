@font-face {
  font-family: "Rosario";
  src: url("fonts/Rosario-VariableFont_wght.ttf"); }

@font-face {
  font-family: "ProximaNova";
  src: url("fonts/ProximaNova.ttf"); }

@font-face {
  font-family: "RopaSans-Italic";
  src: url("fonts/RopaSans-Italic.ttf"); }

@font-face {
  font-family: "RopaSans-Regular";
  src: url("fonts/RopaSans-Regular.ttf"); }

@font-face {
  font-family: "FredokaOne-Regular";
  src: url("fonts/FredokaOne-Regular.ttf"); }

#root {
  background-color: white; }

body {
  background-color: white; }
  @media screen and (max-width: 800px) {
    body .app-container {
      margin: 0 auto;
      padding: 0 20px; } }
  @media screen and (max-width: 1180px) {
    body .app-container {
      margin: 0 auto;
      padding: 0 50px; } }
  @media screen and (min-width: 1180px) {
    body .app-container {
      max-width: 1500px;
      margin: 0 auto;
      padding: 0 95px; } }

body::after {
  position: absolute;
  width: 0;
  height: 0;
  overflow: hidden;
  z-index: -1;
  content: url("img/groupandlot.webp") url("img/boarding.webp") url("img/grooming.webp") url("img/taxi.webp") url("img/carousel1.webp") url("img/maple_carousel.webp") url("img/belmontlocation2.webp") url("img/maplelocation.webp") url("img/arrow-left.svg") url("img/collage.webp") url("img/arrow-right.svg") url("img/paw.svg") url("img/paw2.svg") url("img/paw-pointer.svg"); }

.ant-drawer-content-wrapper {
  width: 100%; }

.ant-btn {
  font-family: "RopaSans-Regular";
  border: 1px solid #333;
  color: #333;
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0);
  margin: 10px 10px; }
  .ant-btn.primary {
    border-color: white;
    color: white; }
    .ant-btn.primary:hover {
      background-color: white;
      border-color: #006261;
      color: #006261; }
  .ant-btn.secondary {
    border-color: #006261;
    color: #006261; }
    .ant-btn.secondary:hover {
      background-color: #006261;
      border-color: white;
      color: white; }
    .ant-btn.secondary.active {
      background-color: #006261;
      border-color: white;
      color: white; }
  .ant-btn.tertiary {
    border-color: #006261;
    color: white;
    background-color: #006261; }
    .ant-btn.tertiary:hover {
      background-color: rgba(0, 0, 0, 0);
      border-color: #006261;
      color: #006261; }
  .ant-btn i.left {
    margin-right: 15px; }
  .ant-btn i.right {
    margin-left: 15px; }
  @media screen and (max-width: 1180px) {
    .ant-btn {
      font-size: 14px;
      padding: 4px 30px;
      height: 40px; } }
  @media screen and (min-width: 1180px) {
    .ant-btn {
      font-size: 14px;
      padding: 4px 50px;
      height: 50px; } }

h1 {
  font-family: "FredokaOne-Regular";
  font-weight: normal;
  color: #054645; }
  @media screen and (max-width: 1180px) {
    h1 {
      font-size: 28px;
      line-height: 38px; } }
  @media screen and (min-width: 1180px) {
    h1 {
      font-size: 35px;
      line-height: 48px; } }
  @media screen and (max-width: 800px) {
    h1 {
      font-size: 22px;
      line-height: 30px; } }

p {
  font-family: "RopaSans-Regular";
  font-weight: 500;
  color: #444444; }
  @media screen and (max-width: 1180px) {
    p {
      font-size: 17px;
      line-height: 30px; } }
  @media screen and (min-width: 1180px) {
    p {
      font-size: 20px;
      line-height: 33px; } }
  @media screen and (max-width: 800px) {
    p {
      font-size: 16px;
      line-height: 26px; } }

.subheader h3 {
  color: #054645;
  font-family: "FredokaOne-Regular";
  margin-bottom: 35px;
  font-weight: normal; }
  .subheader h3 img {
    width: 17px;
    margin-right: 30px;
    margin-bottom: 4px; }
  @media screen and (max-width: 1180px) {
    .subheader h3 {
      padding-left: 50px;
      padding-right: 50px;
      font-size: 13px;
      line-height: 33px; } }
  @media screen and (min-width: 1180px) {
    .subheader h3 {
      font-size: 15px;
      line-height: 33px; } }
  @media screen and (max-width: 800px) {
    .subheader h3 {
      padding-left: 35px;
      padding-right: 35px; } }

.subheader.secondary h3 {
  color: white; }

.compact-info-section {
  float: left; }
  @media screen and (max-width: 1180px) {
    .compact-info-section h1 {
      font-size: 20px;
      line-height: 38px; } }
  @media screen and (min-width: 1180px) {
    .compact-info-section h1 {
      font-size: 25px;
      line-height: 40px; } }
  @media screen and (max-width: 800px) {
    .compact-info-section h1 {
      font-size: 18px;
      line-height: 30px; } }
  @media screen and (max-width: 1180px) {
    .compact-info-section p {
      font-size: 15px;
      line-height: 25px; } }
  @media screen and (min-width: 1180px) {
    .compact-info-section p {
      font-size: 18px;
      line-height: 28px; } }
  @media screen and (max-width: 800px) {
    .compact-info-section p {
      font-size: 15px;
      line-height: 22px; } }

.ant-tabs-tabpane {
  width: 100%; }

.ant-tabs-nav {
  margin: 0px 10px; }
  .ant-tabs-nav .ant-tabs-tab {
    font-size: 16px; }
  .ant-tabs-nav .ant-tabs-nav-list {
    margin: 0 auto; }

.ant-tabs-content-holder {
  width: 100%;
  margin: 0 auto; }
  @media screen and (max-width: 1180px) {
    .ant-tabs-content-holder {
      padding: 50px 30px; } }
  @media screen and (min-width: 1180px) {
    .ant-tabs-content-holder {
      padding: 50px 100px;
      max-width: 2000px; } }
  @media screen and (max-width: 800px) {
    .ant-tabs-content-holder {
      padding: 20px; } }

.card-container {
  background-color: #006261;
  display: flex;
  min-height: 600px;
  float: left;
  width: 100%; }
  @media screen and (max-width: 800px) {
    .card-container {
      flex-direction: column; } }
  .card-container .img-container {
    flex: 0.7;
    background-position: 50% 60%;
    background-repeat: no-repeat;
    background-size: cover; }
    .card-container .img-container.daycare {
      background-image: url("img/groupandlot.webp"); }
    .card-container .img-container.boarding {
      background-image: url("img/boarding.webp"); }
    .card-container .img-container.grooming {
      background-image: url("img/grooming.webp");
      background-position: 10% 35%; }
    .card-container .img-container.taxi {
      background-image: url("img/taxi.webp");
      background-position: 15% 45%; }
    .card-container .img-container.belmont {
      background-image: url("img/belmontlocation2.webp");
      background-position: 0% 30%; }
    .card-container .img-container.maple {
      background-image: url("img/maplelocation.webp");
      background-position: 0% 20%; }
    @media screen and (max-width: 800px) {
      .card-container .img-container {
        min-height: 500px; } }
    .card-container .img-container img {
      width: 100%; }
  .card-container .text-container {
    flex: 0.4;
    padding: 80px 50px;
    width: 100%; }
    @media screen and (max-width: 800px) {
      .card-container .text-container {
        padding: 50px 30px 30px; } }
    .card-container .text-container .header {
      margin-bottom: 50px; }
      @media screen and (max-width: 800px) {
        .card-container .text-container .header {
          margin-bottom: 15px; } }
    .card-container .text-container .description {
      font-size: 17px;
      line-height: 29px;
      margin-bottom: 80px; }
      @media screen and (max-width: 800px) {
        .card-container .text-container .description {
          font-size: 16px;
          margin-bottom: 30px; } }

.first-time-content {
  background-image: url("img/paw2.svg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover; }
  .first-time-content h1 {
    font-family: "FredokaOne-Regular";
    font-weight: bold;
    color: #3e4646; }
    @media screen and (max-width: 1180px) {
      .first-time-content h1 {
        font-size: 28px;
        line-height: 38px; } }
    @media screen and (min-width: 1180px) {
      .first-time-content h1 {
        font-size: 28px;
        line-height: 38px; } }
    @media screen and (max-width: 800px) {
      .first-time-content h1 {
        font-size: 22px;
        line-height: 30px; } }
  .first-time-content h2 {
    font-family: "ProximaNova";
    font-weight: bold;
    color: #3e4646; }
    @media screen and (max-width: 1180px) {
      .first-time-content h2 {
        font-size: 15px;
        line-height: 25px; } }
    @media screen and (min-width: 1180px) {
      .first-time-content h2 {
        font-size: 18px;
        line-height: 25px; } }
    @media screen and (max-width: 800px) {
      .first-time-content h2 {
        font-size: 15px;
        line-height: 22px; } }
  @media screen and (max-width: 1180px) {
    .first-time-content p {
      font-size: 15px;
      line-height: 25px; } }
  @media screen and (min-width: 1180px) {
    .first-time-content p {
      font-size: 18px;
      line-height: 26px; } }
  @media screen and (max-width: 800px) {
    .first-time-content p {
      font-size: 15px;
      line-height: 22px; } }

.ant-tabs-nav .ant-tabs-tab {
  font-family: "RopaSans-Regular";
  font-size: 18px;
  border-radius: 8px;
  border: 1px solid #333; }

.ant-tabs-nav .ant-tabs-nav-list {
  margin: 0; }

.container {
  max-width: 1050px;
  margin: 0 auto;
  display: block !important; }
  @media screen and (max-width: 1180px) {
    .container {
      padding-left: 50px;
      padding-right: 50px; } }
  @media screen and (max-width: 800px) {
    .container {
      padding-left: 35px;
      padding-right: 35px; } }
  @media screen and (min-width: 1180px) {
    .container.button-container {
      padding: 0px 0px 170px; } }
  @media screen and (max-width: 1180px) {
    .container.button-container {
      padding: 0px 50px 130px; } }
  @media screen and (max-width: 800px) {
    .container.button-container {
      padding: 0px 35px 110px; } }
  .container.button-container button {
    margin-left: 0;
    margin-bottom: 0; }

#screen-container {
  margin-top: 100px; }
  @media screen and (max-width: 1000px) {
    #screen-container {
      margin-top: 64px; } }

#header-container {
  background-color: white;
  padding: 0 20px;
  position: fixed;
  z-index: 1;
  width: 100%; }
  @media screen and (max-width: 1000px) {
    #header-container {
      padding: 0 20px 0 10px; } }
  #header-container .header {
    background-color: white;
    font-family: "RopaSans-Regular";
    max-width: 1200px;
    margin: 0 auto;
    padding: 0;
    color: #333; }
    #header-container .header .header-logo-container {
      float: left; }
      #header-container .header .header-logo-container img {
        width: 100px; }
        @media screen and (max-width: 1000px) {
          #header-container .header .header-logo-container img {
            width: 64px; } }
    #header-container .header .header-menu {
      float: right;
      line-height: 22px;
      padding: 38px 0;
      font-size: 15px; }
      @media screen and (max-width: 1000px) {
        #header-container .header .header-menu {
          display: none; } }
    #header-container .header .header-button {
      float: right;
      padding: 4px 30px;
      height: 50px;
      margin: 25px 10px; }
      #header-container .header .header-button:hover {
        border-color: #006261;
        color: white;
        background-color: #006261; }
      @media screen and (max-width: 1000px) {
        #header-container .header .header-button {
          display: none; } }
    #header-container .header .drawer-link {
      float: right;
      color: #006261; }
      @media screen and (min-width: 1000px) {
        #header-container .header .drawer-link {
          display: none; } }

.ant-menu-horizontal {
  border-bottom: 0; }
  .ant-menu-horizontal .ant-menu-item {
    margin: 0 35px !important; }

.slick-slide {
  border: none !important; }

.carousel-container {
  min-height: 650px;
  height: 80vh;
  vertical-align: middle; }
  @media screen and (max-width: 1180px) {
    .carousel-container {
      height: 62px; } }
  @media screen and (min-width: 1180px) {
    .carousel-container {
      height: auto; } }
  .carousel-container .carousel-main {
    float: right; }
    @media screen and (max-width: 1180px) {
      .carousel-container .carousel-main {
        width: 100%; } }
    @media screen and (min-width: 1180px) {
      .carousel-container .carousel-main {
        width: 55%; } }
    .carousel-container .carousel-main .carousel-slide {
      background-repeat: no-repeat;
      background-size: cover;
      border: none; }
      .carousel-container .carousel-main .carousel-slide.carousel1 {
        background-image: url("img/carousel1.webp"); }
      .carousel-container .carousel-main .carousel-slide.maple_carousel {
        background-image: url("img/maple_carousel.webp"); }
      @media screen and (max-width: 1180px) {
        .carousel-container .carousel-main .carousel-slide {
          min-height: 350px;
          height: 50vh; } }
      @media screen and (min-width: 1180px) {
        .carousel-container .carousel-main .carousel-slide {
          min-height: 650px;
          height: 80vh; } }
  .carousel-container .carousel-text-area {
    font-family: "FredokaOne-Regular";
    background-color: #006261;
    color: white;
    float: left; }
    @media screen and (max-width: 1180px) {
      .carousel-container .carousel-text-area {
        width: 100%;
        min-height: 300px; } }
    @media screen and (min-width: 1180px) {
      .carousel-container .carousel-text-area {
        min-height: 650px;
        height: 80vh;
        width: 45%; } }
    @media screen and (max-width: 800px) {
      .carousel-container .carousel-text-area {
        width: 100%;
        min-height: 250px; } }
    .carousel-container .carousel-text-area .inactive {
      display: none; }
    .carousel-container .carousel-text-area .active {
      height: auto;
      display: block; }
    .carousel-container .carousel-text-area .text-slide-container {
      width: 100%;
      color: white; }
      @media screen and (max-width: 1180px) {
        .carousel-container .carousel-text-area .text-slide-container {
          padding: 50px 70px;
          max-width: 620px; } }
      @media screen and (min-width: 1180px) {
        .carousel-container .carousel-text-area .text-slide-container {
          padding: 110px 70px;
          max-width: 620px;
          margin: 0 auto; } }
      @media screen and (max-width: 800px) {
        .carousel-container .carousel-text-area .text-slide-container {
          padding: 40px 60px;
          max-width: 620px; } }
      @media screen and (max-width: 1180px) {
        .carousel-container .carousel-text-area .text-slide-container .header-text {
          font-size: 30px;
          margin: 0px 0 20px; } }
      @media screen and (min-width: 1180px) {
        .carousel-container .carousel-text-area .text-slide-container .header-text {
          font-size: 46px;
          margin: 0px 0 50px; } }
      .carousel-container .carousel-text-area .text-slide-container .sub-text {
        margin-top: 50px; }
        @media screen and (max-width: 1180px) {
          .carousel-container .carousel-text-area .text-slide-container .sub-text {
            font-size: 13px;
            line-height: 33px; } }
        @media screen and (min-width: 1180px) {
          .carousel-container .carousel-text-area .text-slide-container .sub-text {
            font-size: 15px;
            line-height: 33px; } }
      .carousel-container .carousel-text-area .text-slide-container .arrow {
        padding: 10px 17px 12px;
        margin-right: 7px;
        border-radius: 8px;
        background-color: #004D4C;
        transition-duration: 1s; }
        .carousel-container .carousel-text-area .text-slide-container .arrow:hover {
          background-color: #004847;
          transition-duration: 1s; }
      .carousel-container .carousel-text-area .text-slide-container button {
        margin-right: 0;
        margin-left: 0; }
  .carousel-container .carousel-arrow {
    position: absolute;
    z-index: 100;
    color: white;
    font-size: 40px; }
    @media screen and (max-width: 1180px) {
      .carousel-container .carousel-arrow {
        font-size: 30px;
        top: 40%; } }
    @media screen and (min-width: 1180px) {
      .carousel-container .carousel-arrow {
        font-size: 40px;
        top: 55%; } }
    .carousel-container .carousel-arrow.left {
      left: 0;
      margin-left: 10px; }
    .carousel-container .carousel-arrow.right {
      right: 0;
      margin-right: 10px; }

.location-container {
  font-family: "FredokaOne-Regular";
  float: left;
  width: 100%;
  background-color: white;
  margin: 0 auto;
  padding: 110px 0 70px; }
  .location-container .location-sub-container {
    max-width: 1050px;
    margin: 0 auto;
    display: block; }
  .location-container .location-cards {
    float: left;
    width: 100%; }
    .location-container .location-cards .location-card-container {
      float: left;
      width: 50%;
      background-color: white;
      margin-bottom: 50px; }
      @media screen and (max-width: 1180px) {
        .location-container .location-cards .location-card-container {
          padding: 0 50px; } }
      @media screen and (max-width: 800px) {
        .location-container .location-cards .location-card-container {
          width: 100%;
          padding: 0 35px; } }
      .location-container .location-cards .location-card-container .location-information {
        float: left;
        width: 100%; }
        .location-container .location-cards .location-card-container .location-information .location-header {
          vertical-align: middle;
          float: left;
          font-family: "FredokaOne-Regular"; }
          .location-container .location-cards .location-card-container .location-information .location-header p {
            color: #054645;
            font-family: "FredokaOne-Regular";
            margin: 0;
            padding: 0;
            font-size: 14px;
            line-height: 5px; }
          .location-container .location-cards .location-card-container .location-information .location-header h1 {
            color: #054645;
            font-weight: normal;
            font-family: "FredokaOne-Regular";
            float: left;
            margin: 0;
            width: 100%; }
            @media screen and (min-width: 1180px) {
              .location-container .location-cards .location-card-container .location-information .location-header h1 {
                font-size: 25px;
                margin-bottom: 15px; } }
            @media screen and (max-width: 1180px) {
              .location-container .location-cards .location-card-container .location-information .location-header h1 {
                margin-bottom: 10px; } }
        .location-container .location-cards .location-card-container .location-information .location-details {
          float: left;
          width: 100%; }
          .location-container .location-cards .location-card-container .location-information .location-details p {
            font-size: 16px;
            line-height: 22px;
            color: #333333; }
          @media screen and (max-width: 1180px) {
            .location-container .location-cards .location-card-container .location-information .location-details {
              font-size: 15px;
              margin-bottom: 18px; } }
          @media screen and (min-width: 1180px) {
            .location-container .location-cards .location-card-container .location-information .location-details {
              margin-bottom: 18px; } }
          @media screen and (max-width: 800px) {
            .location-container .location-cards .location-card-container .location-information .location-details {
              font-size: 12px;
              margin-bottom: 9px; } }
          .location-container .location-cards .location-card-container .location-information .location-details h3.primary {
            color: #e7eeee; }
          .location-container .location-cards .location-card-container .location-information .location-details h3.secondary {
            color: #333333; }
          .location-container .location-cards .location-card-container .location-information .location-details .address {
            margin: 0; }
          .location-container .location-cards .location-card-container .location-information .location-details .hours {
            float: left;
            width: 60%; }
          .location-container .location-cards .location-card-container .location-information .location-details .contact-link {
            float: right;
            color: white; }
            @media screen and (max-width: 1180px) {
              .location-container .location-cards .location-card-container .location-information .location-details .contact-link {
                margin-top: -10px;
                font-size: 35px; } }
            @media screen and (min-width: 1180px) {
              .location-container .location-cards .location-card-container .location-information .location-details .contact-link {
                margin-top: -10px;
                font-size: 35px; } }
            @media screen and (max-width: 800px) {
              .location-container .location-cards .location-card-container .location-information .location-details .contact-link {
                margin-top: -7px;
                font-size: 25px; } }
          .location-container .location-cards .location-card-container .location-information .location-details a {
            text-decoration: underline; }
            .location-container .location-cards .location-card-container .location-information .location-details a.primary {
              color: #00efee; }
            .location-container .location-cards .location-card-container .location-information .location-details a.secondary {
              color: #00b1b0; }
          .location-container .location-cards .location-card-container .location-information .location-details.last {
            margin-bottom: 0; }
        .location-container .location-cards .location-card-container .location-information .location-buttons {
          float: left; }
          .location-container .location-cards .location-card-container .location-information .location-buttons .ant-btn {
            margin-left: 0;
            margin-right: 5px;
            padding-left: 25px;
            padding-right: 25px;
            margin-bottom: 0px;
            margin-top: 5px; }

.location-map {
  float: left;
  width: 100%;
  height: 50vh; }

.ant-modal-body button {
  height: 40px; }

.ant-modal-confirm-title {
  margin-bottom: 30px; }

.location-details-container {
  background-color: #f0f9f9; }
  .location-details-container button {
    margin-left: 0;
    margin-bottom: 0; }
  .location-details-container .ant-carousel {
    background-color: #006261; }
  .location-details-container .button-container {
    margin-bottom: 50px; }
  .location-details-container .text-container {
    background-color: #006261; }
    .location-details-container .text-container p {
      color: white;
      font-weight: normal; }
    .location-details-container .text-container .header {
      font-family: "FredokaOne-Regular"; }

.info-section-container {
  float: left;
  width: 100%;
  text-align: left;
  background-color: #F0F9F9;
  padding-top: 10px;
  padding-bottom: 120px; }
  @media screen and (max-width: 1180px) {
    .info-section-container {
      padding-bottom: 95px; } }
  @media screen and (min-width: 1180px) {
    .info-section-container {
      padding-bottom: 120px; } }
  @media screen and (max-width: 800px) {
    .info-section-container {
      padding-bottom: 85px; } }
  .info-section-container .info-section-content {
    max-width: 1050px;
    margin: 0 auto;
    display: block; }
    @media screen and (max-width: 1180px) {
      .info-section-container .info-section-content {
        padding-top: 100px; } }
    @media screen and (min-width: 1180px) {
      .info-section-container .info-section-content {
        padding-top: 140px; } }
    @media screen and (max-width: 800px) {
      .info-section-container .info-section-content {
        padding-top: 80px; } }
    .info-section-container .info-section-content h1 {
      color: #054645;
      font-family: "FredokaOne-Regular";
      font-weight: normal;
      margin: 0 auto; }
      @media screen and (max-width: 1180px) {
        .info-section-container .info-section-content h1 {
          padding-left: 50px;
          padding-right: 50px; } }
      @media screen and (min-width: 1180px) {
        .info-section-container .info-section-content h1 {
          font-size: 38px; } }
      @media screen and (max-width: 800px) {
        .info-section-container .info-section-content h1 {
          padding-left: 35px;
          padding-right: 35px; } }
    .info-section-container .info-section-content h3 {
      color: #054645;
      font-family: "FredokaOne-Regular";
      margin-bottom: 25px;
      font-weight: normal; }
      .info-section-container .info-section-content h3 img {
        width: 17px;
        margin-right: 30px;
        margin-bottom: 4px; }
      @media screen and (max-width: 1180px) {
        .info-section-container .info-section-content h3 {
          padding-left: 50px;
          padding-right: 50px;
          font-size: 13px;
          line-height: 33px; } }
      @media screen and (min-width: 1180px) {
        .info-section-container .info-section-content h3 {
          font-size: 15px;
          line-height: 33px; } }
      @media screen and (max-width: 800px) {
        .info-section-container .info-section-content h3 {
          padding-left: 35px;
          padding-right: 35px; } }
    @media screen and (max-width: 1180px) {
      .info-section-container .info-section-content p {
        padding-left: 50px;
        padding-right: 50px;
        padding-top: 35px;
        margin-bottom: 35px; } }
    @media screen and (min-width: 1180px) {
      .info-section-container .info-section-content p {
        margin-bottom: 50px;
        margin-top: 50px; } }
    @media screen and (max-width: 800px) {
      .info-section-container .info-section-content p {
        padding-left: 35px;
        padding-right: 35px;
        padding-top: 25px;
        margin-bottom: 20px; } }
    .info-section-container .info-section-content .button-container {
      float: left; }
      .info-section-container .info-section-content .button-container button {
        margin-left: 0;
        margin-top: 0; }
      @media screen and (max-width: 1180px) {
        .info-section-container .info-section-content .button-container {
          padding-left: 50px;
          padding-right: 50px; } }
      @media screen and (max-width: 800px) {
        .info-section-container .info-section-content .button-container {
          padding-left: 35px;
          padding-right: 35px; } }

.img-info-section-container {
  width: 100%;
  float: left;
  background-color: #006261;
  padding-bottom: 20px; }
  .img-info-section-container .img-info-section-sub-container {
    max-width: 1050px;
    margin: 0 auto;
    display: block; }
    .img-info-section-container .img-info-section-sub-container .img-info-section-content {
      float: left; }
      @media screen and (max-width: 1180px) {
        .img-info-section-container .img-info-section-sub-container .img-info-section-content {
          width: 50%;
          padding-top: 60px; } }
      @media screen and (min-width: 1180px) {
        .img-info-section-container .img-info-section-sub-container .img-info-section-content {
          width: 40%;
          padding-top: 80px; } }
      @media screen and (max-width: 800px) {
        .img-info-section-container .img-info-section-sub-container .img-info-section-content {
          width: 100%;
          padding-top: 45px; } }
      .img-info-section-container .img-info-section-sub-container .img-info-section-content h1 {
        color: white;
        font-family: "FredokaOne-Regular";
        font-weight: normal;
        margin: 0 auto; }
        @media screen and (max-width: 1180px) {
          .img-info-section-container .img-info-section-sub-container .img-info-section-content h1 {
            padding-left: 50px;
            padding-right: 50px; } }
        @media screen and (min-width: 1180px) {
          .img-info-section-container .img-info-section-sub-container .img-info-section-content h1 {
            font-size: 38px; } }
        @media screen and (max-width: 800px) {
          .img-info-section-container .img-info-section-sub-container .img-info-section-content h1 {
            padding-left: 35px;
            padding-right: 35px; } }
      .img-info-section-container .img-info-section-sub-container .img-info-section-content p {
        color: white; }
        @media screen and (max-width: 1180px) {
          .img-info-section-container .img-info-section-sub-container .img-info-section-content p {
            padding-left: 50px;
            padding-right: 50px;
            padding-top: 35px;
            margin-bottom: 35px; } }
        @media screen and (min-width: 1180px) {
          .img-info-section-container .img-info-section-sub-container .img-info-section-content p {
            margin-bottom: 35px;
            margin-top: 50px; } }
        @media screen and (max-width: 800px) {
          .img-info-section-container .img-info-section-sub-container .img-info-section-content p {
            padding-left: 35px;
            padding-right: 35px;
            padding-top: 25px;
            margin-bottom: 35px; } }
      .img-info-section-container .img-info-section-sub-container .img-info-section-content button {
        margin-left: 0; }
        @media screen and (max-width: 1180px) {
          .img-info-section-container .img-info-section-sub-container .img-info-section-content button {
            margin-left: 50px; } }
        @media screen and (max-width: 800px) {
          .img-info-section-container .img-info-section-sub-container .img-info-section-content button {
            margin-left: 35px; } }
    .img-info-section-container .img-info-section-sub-container .img-info-section-img {
      width: 50%;
      float: right; }
      @media screen and (max-width: 800px) {
        .img-info-section-container .img-info-section-sub-container .img-info-section-img {
          width: 95%; } }
      .img-info-section-container .img-info-section-sub-container .img-info-section-img img {
        overflow: hidden;
        transform: translate(50px, -30px);
        border-radius: 5px;
        object-fit: cover;
        height: 550px;
        width: 100%;
        object-position: 50% 100%; }
        @media screen and (max-width: 1180px) {
          .img-info-section-container .img-info-section-sub-container .img-info-section-img img {
            transform: translate(0px, -30px);
            border-radius: 5px 0 0 5px; } }
        @media screen and (max-width: 800px) {
          .img-info-section-container .img-info-section-sub-container .img-info-section-img img {
            height: 350px;
            transform: translate(0px, 0px);
            margin-top: 20px;
            border-radius: 5px 0 0 5px; } }

.services-container {
  width: 100%;
  float: left;
  padding-bottom: 80px; }
  @media screen and (max-width: 800px) {
    .services-container {
      padding-top: 0px; } }
  .services-container .services-card-container-container {
    margin: 0 auto;
    display: flex;
    max-width: 1300px; }
    @media screen and (max-width: 1180px) {
      .services-container .services-card-container-container {
        flex-direction: row; } }
    @media screen and (min-width: 1180px) {
      .services-container .services-card-container-container {
        flex-direction: row; } }
    @media screen and (max-width: 800px) {
      .services-container .services-card-container-container {
        flex-direction: column; } }
    .services-container .services-card-container-container .services-card-container {
      flex: 1;
      margin: 25px; }
      @media screen and (max-width: 1180px) {
        .services-container .services-card-container-container .services-card-container {
          margin: 10px; } }
      @media screen and (min-width: 1180px) {
        .services-container .services-card-container-container .services-card-container {
          margin: 25px; } }
      @media screen and (max-width: 800px) {
        .services-container .services-card-container-container .services-card-container {
          margin: 10px 35px; } }
      .services-container .services-card-container-container .services-card-container .text-container {
        float: left;
        margin-top: 30px; }
        .services-container .services-card-container-container .services-card-container .text-container .header {
          color: #006261;
          font-family: "FredokaOne-Regular"; }
          @media screen and (max-width: 1180px) {
            .services-container .services-card-container-container .services-card-container .text-container .header {
              font-size: 16px;
              height: 30px; } }
          @media screen and (min-width: 1180px) {
            .services-container .services-card-container-container .services-card-container .text-container .header {
              font-size: 19px;
              height: auto; } }
        .services-container .services-card-container-container .services-card-container .text-container .description {
          color: #333; }
          @media screen and (max-width: 1180px) {
            .services-container .services-card-container-container .services-card-container .text-container .description {
              font-size: 15px;
              line-height: 22px; } }
          @media screen and (min-width: 1180px) {
            .services-container .services-card-container-container .services-card-container .text-container .description {
              font-size: 18px;
              line-height: 24px; } }
        .services-container .services-card-container-container .services-card-container .text-container a {
          bottom: 0; }
      .services-container .services-card-container-container .services-card-container .img-container {
        width: 100%;
        float: left; }
        @media screen and (max-width: 1180px) {
          .services-container .services-card-container-container .services-card-container .img-container {
            height: 190px; } }
        @media screen and (min-width: 1180px) {
          .services-container .services-card-container-container .services-card-container .img-container {
            height: 260px; } }
        .services-container .services-card-container-container .services-card-container .img-container img {
          border-radius: 5px;
          width: 100%;
          object-fit: cover; }
          @media screen and (max-width: 1180px) {
            .services-container .services-card-container-container .services-card-container .img-container img {
              height: 190px; } }
          @media screen and (min-width: 1180px) {
            .services-container .services-card-container-container .services-card-container .img-container img {
              height: 260px; } }
  .services-container .button-container {
    margin-top: 50px;
    text-align: center; }

.services-screen-container {
  float: left;
  width: 100%; }
  .services-screen-container .card-container {
    background-color: white;
    display: flex;
    min-height: 600px;
    float: left;
    width: 100%; }
    @media screen and (max-width: 800px) {
      .services-screen-container .card-container {
        flex-direction: column; } }
    .services-screen-container .card-container .img-container {
      flex: 0.7;
      background-position: 50% 60%;
      background-repeat: no-repeat;
      background-size: cover; }
      .services-screen-container .card-container .img-container.daycare {
        background-image: url("img/groupandlot.webp"); }
      .services-screen-container .card-container .img-container.boarding {
        background-image: url("img/boarding.webp"); }
      .services-screen-container .card-container .img-container.grooming {
        background-image: url("img/grooming.webp");
        background-position: 10% 35%; }
      .services-screen-container .card-container .img-container.taxi {
        background-image: url("img/taxi.webp");
        background-position: 15% 45%; }
      @media screen and (max-width: 800px) {
        .services-screen-container .card-container .img-container {
          min-height: 500px; } }
      .services-screen-container .card-container .img-container img {
        width: 100%; }
    .services-screen-container .card-container .text-container {
      flex: 0.4;
      padding: 80px 50px;
      width: 100%; }
      @media screen and (max-width: 800px) {
        .services-screen-container .card-container .text-container {
          padding: 50px 30px 30px; } }
      .services-screen-container .card-container .text-container .header {
        font-family: "FredokaOne-Regular";
        color: #054645;
        margin-bottom: 50px; }
        @media screen and (max-width: 800px) {
          .services-screen-container .card-container .text-container .header {
            margin-bottom: 20px;
            font-size: 18px; } }
      .services-screen-container .card-container .text-container .description {
        font-size: 17px;
        line-height: 29px;
        margin-bottom: 80px; }
  .services-screen-container .pricing-info-header h1 {
    font-size: 28px;
    margin-bottom: 20px; }
  .services-screen-container .pricing-info-header p {
    font-size: 18px;
    margin-bottom: 0;
    line-height: 30px; }
  .services-screen-container .pricing-container {
    margin-top: 50px;
    float: left;
    text-align: center;
    width: 100%; }
    .services-screen-container .pricing-container .pricing-card-container-container {
      margin: 0 auto;
      display: flex;
      width: 100%;
      float: left;
      justify-items: center;
      align-content: center; }
      @media screen and (max-width: 1180px) {
        .services-screen-container .pricing-container .pricing-card-container-container {
          flex-direction: column; } }
      @media screen and (min-width: 1180px) {
        .services-screen-container .pricing-container .pricing-card-container-container {
          flex-direction: row; } }
      .services-screen-container .pricing-container .pricing-card-container-container .pricing-card-container {
        flex: 1;
        margin: 7px; }
        @media screen and (max-width: 1180px) {
          .services-screen-container .pricing-container .pricing-card-container-container .pricing-card-container {
            margin: -35px 0 0 0; }
            .services-screen-container .pricing-container .pricing-card-container-container .pricing-card-container.true {
              margin: 10px 0 0 0; } }
        .services-screen-container .pricing-container .pricing-card-container-container .pricing-card-container .pricing-card-info {
          background-color: white;
          border-radius: 5px;
          box-shadow: 1px 2px 15px #cccccc;
          display: flex; }
          @media screen and (max-width: 1180px) {
            .services-screen-container .pricing-container .pricing-card-container-container .pricing-card-container .pricing-card-info {
              flex-direction: row;
              padding: 10px 15px; } }
          @media screen and (min-width: 1180px) {
            .services-screen-container .pricing-container .pricing-card-container-container .pricing-card-container .pricing-card-info {
              flex-direction: column; } }
          .services-screen-container .pricing-container .pricing-card-container-container .pricing-card-container .pricing-card-info.true {
            border-radius: 0 0 5px 5px; }
          .services-screen-container .pricing-container .pricing-card-container-container .pricing-card-container .pricing-card-info span {
            flex: 1;
            float: left;
            height: 100%; }
            .services-screen-container .pricing-container .pricing-card-container-container .pricing-card-container .pricing-card-info span.title {
              font-size: 30px;
              padding: 20px 10px 0;
              font-family: "ProximaNova";
              float: left; }
              @media screen and (max-width: 1180px) {
                .services-screen-container .pricing-container .pricing-card-container-container .pricing-card-container .pricing-card-info span.title {
                  font-size: 20px;
                  padding: 0;
                  padding-right: 3px; } }
            .services-screen-container .pricing-container .pricing-card-container-container .pricing-card-container .pricing-card-info span.description {
              margin: 10px 10px;
              color: #7d7d7d;
              min-height: 50px; }
              @media screen and (max-width: 1180px) {
                .services-screen-container .pricing-container .pricing-card-container-container .pricing-card-container .pricing-card-info span.description {
                  font-size: 12px;
                  margin: 0;
                  vertical-align: middle;
                  text-align: left;
                  padding: 0;
                  padding-right: 3px; } }
            .services-screen-container .pricing-container .pricing-card-container-container .pricing-card-container .pricing-card-info span.totalPrice {
              font-size: 35px;
              margin: 20px 10px 50px;
              font-weight: bold; }
              @media screen and (max-width: 1180px) {
                .services-screen-container .pricing-container .pricing-card-container-container .pricing-card-container .pricing-card-info span.totalPrice {
                  font-size: 23px;
                  margin: 0;
                  vertical-align: middle;
                  text-align: center; } }
            .services-screen-container .pricing-container .pricing-card-container-container .pricing-card-container .pricing-card-info span.perDayPrice {
              font-size: 19px;
              margin-bottom: 30px;
              color: #7d7d7d; }
              @media screen and (max-width: 1180px) {
                .services-screen-container .pricing-container .pricing-card-container-container .pricing-card-container .pricing-card-info span.perDayPrice {
                  line-height: 38px;
                  font-size: 16px;
                  margin: 0;
                  vertical-align: middle;
                  text-align: center; } }
        .services-screen-container .pricing-container .pricing-card-container-container .pricing-card-container .bestValue {
          background-color: #ffe7a6;
          border: 1px solid #deac23;
          box-shadow: 1px 2px 15px #cccccc;
          border-radius: 5px 5px 0 0;
          line-height: 45px;
          color: #5e4602;
          font-size: 18px; }
          .services-screen-container .pricing-container .pricing-card-container-container .pricing-card-container .bestValue.true {
            display: block; }
          .services-screen-container .pricing-container .pricing-card-container-container .pricing-card-container .bestValue.false {
            visibility: hidden; }
  .services-screen-container .menu-container {
    max-width: 750px;
    margin: 0 auto; }
    .services-screen-container .menu-container h1 {
      font-size: 20px;
      line-height: 30px;
      text-align: center;
      padding-top: 40px;
      float: left;
      align-self: center;
      width: 100%; }
    .services-screen-container .menu-container p {
      float: left;
      width: 100%;
      text-align: center;
      font-size: 18px;
      margin: -10px 0 10px 0;
      font-style: italic; }
    .services-screen-container .menu-container .menu {
      width: 95%;
      margin: 2.5%;
      background-color: white;
      float: left;
      padding: 30px;
      border-radius: 5px;
      box-shadow: 1px 2px 15px #cccccc; }
      .services-screen-container .menu-container .menu .menu-item {
        float: left;
        width: 100%;
        border-bottom: 1px solid #dceeee; }
        .services-screen-container .menu-container .menu .menu-item .main {
          display: flex;
          color: #333;
          line-height: 40px;
          font-family: "ProximaNova";
          font-size: 17px;
          width: 100%; }
          .services-screen-container .menu-container .menu .menu-item .main .text {
            float: left;
            text-align: left;
            flex: 1;
            font-weight: bold;
            color: #006d6d; }
            .services-screen-container .menu-container .menu .menu-item .main .text .description {
              font-size: 15px;
              font-weight: 400; }
          .services-screen-container .menu-container .menu .menu-item .main .price {
            float: left;
            flex: 0.1;
            text-align: right;
            font-style: italic;
            color: #6b6b6b; }
        .services-screen-container .menu-container .menu .menu-item .description {
          float: left;
          width: 100%;
          line-height: 30px;
          margin-top: -10px;
          color: #255252; }
  .services-screen-container .ant-tabs-tabpane {
    width: 100%; }
  .services-screen-container .ant-tabs-nav {
    margin: 0px 10px; }
    .services-screen-container .ant-tabs-nav .ant-tabs-tab {
      font-size: 16px; }
    .services-screen-container .ant-tabs-nav .ant-tabs-nav-list {
      margin: 0 auto; }
  .services-screen-container .ant-tabs-content-holder {
    width: 100%;
    margin: 0 auto; }
    @media screen and (max-width: 1180px) {
      .services-screen-container .ant-tabs-content-holder {
        padding: 50px 30px; } }
    @media screen and (min-width: 1180px) {
      .services-screen-container .ant-tabs-content-holder {
        padding: 50px 100px;
        max-width: 2000px; } }
    @media screen and (max-width: 800px) {
      .services-screen-container .ant-tabs-content-holder {
        padding: 20px; } }

.taxi-section-container {
  width: 100%;
  float: left; }
  .taxi-section-container .img-content {
    width: 50%;
    float: left; }
    .taxi-section-container .img-content img {
      object-fit: cover;
      height: 620px;
      width: 100%;
      object-position: 50% 100%; }
      @media screen and (max-width: 800px) {
        .taxi-section-container .img-content img {
          height: 350px; } }
    @media screen and (min-width: 1180px) {
      .taxi-section-container .img-content {
        width: 50%;
        height: 620px; } }
    @media screen and (min-width: 1180px) {
      .taxi-section-container .img-content {
        width: 50%;
        height: 620px; } }
    @media screen and (max-width: 800px) {
      .taxi-section-container .img-content {
        width: 100%;
        height: 350px; } }
  .taxi-section-container .text-content {
    float: left;
    height: 620px;
    padding: 120px 80px;
    background-color: #c5e3e3;
    background-image: url("img/paw.svg");
    background-position: left center;
    background-repeat: no-repeat;
    background-size: cover; }
    .taxi-section-container .text-content h1 {
      max-width: 400px;
      margin: 0px 0 50px; }
      @media screen and (max-width: 800px) {
        .taxi-section-container .text-content h1 {
          margin: 0px 0 25px; } }
    .taxi-section-container .text-content p {
      max-width: 700px; }
    .taxi-section-container .text-content button {
      margin-top: 10px; }
    @media screen and (max-width: 1180px) {
      .taxi-section-container .text-content {
        padding: 100px 60px;
        width: 50%;
        height: 620px; } }
    @media screen and (min-width: 1180px) {
      .taxi-section-container .text-content {
        padding: 120px 80px;
        width: 50%;
        height: 620px; } }
    @media screen and (max-width: 800px) {
      .taxi-section-container .text-content {
        padding: 50px 30px;
        width: 100%;
        height: auto; } }

.review-container {
  float: left;
  width: 100%;
  font-family: "ProximaNova"; }
  @media screen and (max-width: 1180px) {
    .review-container {
      margin-top: 65px; } }
  @media screen and (min-width: 1180px) {
    .review-container {
      margin-top: 80px; } }
  @media screen and (max-width: 800px) {
    .review-container {
      margin-top: 40px; } }
  .review-container .header {
    max-width: 700px;
    text-align: center;
    margin: 0 auto 80px;
    display: block;
    background-color: #f0f2f5;
    padding: 0 20px; }
    @media screen and (max-width: 800px) {
      .review-container .header {
        margin-bottom: 60px; } }
    .review-container .header img {
      width: 100px; }
      @media screen and (max-width: 800px) {
        .review-container .header img {
          width: 65px; } }
  .review-container .user-reviews {
    width: 100%;
    background-color: white;
    float: left;
    margin-bottom: 20px; }
    .review-container .user-reviews .reviewers {
      margin: 0 auto;
      display: block;
      max-width: 1200px; }
      .review-container .user-reviews .reviewers .reviewer-row {
        width: 100%;
        display: flex;
        border-bottom: 1px solid #ededed; }
        @media screen and (max-width: 1180px) {
          .review-container .user-reviews .reviewers .reviewer-row {
            flex-direction: column;
            padding: 30px 55px; } }
        @media screen and (min-width: 1180px) {
          .review-container .user-reviews .reviewers .reviewer-row {
            flex-direction: row;
            padding: 55px 35px; } }
        @media screen and (max-width: 800px) {
          .review-container .user-reviews .reviewers .reviewer-row {
            flex-direction: column;
            padding: 30px 30px; } }
        .review-container .user-reviews .reviewers .reviewer-row .reviewer-info {
          display: flex;
          float: left; }
          @media screen and (max-width: 1180px) {
            .review-container .user-reviews .reviewers .reviewer-row .reviewer-info {
              flex: 1;
              margin-bottom: 15px; } }
          @media screen and (min-width: 1180px) {
            .review-container .user-reviews .reviewers .reviewer-row .reviewer-info {
              flex: 0.3; } }
          @media screen and (max-width: 800px) {
            .review-container .user-reviews .reviewers .reviewer-row .reviewer-info {
              flex: 1;
              margin-bottom: 15px; } }
          .review-container .user-reviews .reviewers .reviewer-row .reviewer-info img {
            width: 52px;
            height: 52px;
            margin-right: 20px; }
          .review-container .user-reviews .reviewers .reviewer-row .reviewer-info h3 {
            margin: 0; }
          .review-container .user-reviews .reviewers .reviewer-row .reviewer-info .reviewer-details {
            flex: 1.2; }
          .review-container .user-reviews .reviewers .reviewer-row .reviewer-info .ant-rate li {
            margin: 0 5px 0 0; }
          .review-container .user-reviews .reviewers .reviewer-row .reviewer-info .ant-rate svg {
            width: 15px; }
        .review-container .user-reviews .reviewers .reviewer-row .review-content {
          float: left;
          flex: 1;
          background-image: url("img/quotation.svg");
          background-repeat: no-repeat;
          background-position: left top;
          background-size: 60px; }
  .review-container .button-container {
    text-align: center;
    margin-bottom: 30px; }

.review-section-container {
  width: 100%;
  float: left;
  background-color: #f0f9f9;
  padding-bottom: 100px;
  padding-top: 55px; }
  .review-section-container .review-section-sub-container {
    max-width: 1050px;
    margin: 0 auto;
    display: block; }
    .review-section-container .review-section-sub-container .review-section-content {
      float: left; }
      @media screen and (max-width: 1180px) {
        .review-section-container .review-section-sub-container .review-section-content {
          width: 50%;
          padding-top: 60px; } }
      @media screen and (min-width: 1180px) {
        .review-section-container .review-section-sub-container .review-section-content {
          width: 40%;
          padding-top: 80px; } }
      @media screen and (max-width: 800px) {
        .review-section-container .review-section-sub-container .review-section-content {
          width: 100%;
          padding-top: 45px; } }
      .review-section-container .review-section-sub-container .review-section-content h1 {
        color: #054645;
        font-family: "FredokaOne-Regular";
        font-weight: normal;
        margin: 0 auto;
        padding-bottom: 20px; }
        @media screen and (max-width: 1180px) {
          .review-section-container .review-section-sub-container .review-section-content h1 {
            padding-left: 50px;
            padding-right: 50px; } }
        @media screen and (min-width: 1180px) {
          .review-section-container .review-section-sub-container .review-section-content h1 {
            font-size: 38px; } }
        @media screen and (max-width: 800px) {
          .review-section-container .review-section-sub-container .review-section-content h1 {
            padding-left: 35px;
            padding-right: 35px; } }
      .review-section-container .review-section-sub-container .review-section-content p {
        color: white;
        font-size: 18px; }
        @media screen and (max-width: 1180px) {
          .review-section-container .review-section-sub-container .review-section-content p {
            padding-left: 50px;
            padding-right: 50px;
            padding-top: 35px;
            margin-bottom: 35px; } }
        @media screen and (min-width: 1180px) {
          .review-section-container .review-section-sub-container .review-section-content p {
            margin-bottom: 35px;
            margin-top: 50px; } }
        @media screen and (max-width: 800px) {
          .review-section-container .review-section-sub-container .review-section-content p {
            padding-left: 35px;
            padding-right: 35px;
            padding-top: 25px;
            margin-bottom: 35px; } }
      .review-section-container .review-section-sub-container .review-section-content .button-container {
        float: left; }
        @media screen and (max-width: 1180px) {
          .review-section-container .review-section-sub-container .review-section-content .button-container {
            margin-left: 50px; } }
        @media screen and (max-width: 800px) {
          .review-section-container .review-section-sub-container .review-section-content .button-container {
            margin-left: 35px;
            margin-bottom: 20px; } }
      .review-section-container .review-section-sub-container .review-section-content button {
        margin-left: 0; }
        @media screen and (max-width: 1180px) {
          .review-section-container .review-section-sub-container .review-section-content button {
            height: 45px;
            margin-bottom: 0; } }
    .review-section-container .review-section-sub-container .review-section-carousel {
      width: 44%;
      float: right;
      margin-top: 50px; }
      @media screen and (max-width: 1180px) {
        .review-section-container .review-section-sub-container .review-section-carousel {
          margin-right: 20px; } }
      @media screen and (max-width: 800px) {
        .review-section-container .review-section-sub-container .review-section-carousel {
          width: 90%;
          margin: 5%; } }
      .review-section-container .review-section-sub-container .review-section-carousel .review-carousel {
        box-shadow: 1px 2px 15px #cccccc;
        margin-bottom: 30px; }
        .review-section-container .review-section-sub-container .review-section-carousel .review-carousel .slide-container {
          padding: 55px 45px;
          background-color: white;
          height: 380px;
          border-radius: 5px; }
          .review-section-container .review-section-sub-container .review-section-carousel .review-carousel .slide-container .reviewer-block {
            bottom: 40px;
            position: absolute; }
          .review-section-container .review-section-sub-container .review-section-carousel .review-carousel .slide-container h1 {
            font-family: "FredokaOne-Regular";
            color: #054645;
            font-weight: normal;
            font-size: 21px;
            margin: 0;
            line-height: 20px; }
            @media screen and (max-width: 800px) {
              .review-section-container .review-section-sub-container .review-section-carousel .review-carousel .slide-container h1 {
                font-size: 17px; } }
      .review-section-container .review-section-sub-container .review-section-carousel .arrow {
        padding: 10px 17px 12px;
        margin-right: 7px;
        border-radius: 8px;
        background-color: #cbe0e0;
        transition-duration: 1s; }
        .review-section-container .review-section-sub-container .review-section-carousel .arrow:hover {
          background-color: #bfd8d8;
          transition-duration: 1s; }

.ant-rate {
  color: #f9b403; }
  .ant-rate .ant-rate-star {
    margin-right: 3px; }

.footer-container {
  float: left;
  background-color: #243e3d;
  width: 100%;
  display: block;
  font-family: "RopaSans-Regular";
  color: white;
  padding-top: 90px;
  padding-bottom: 90px; }
  @media screen and (max-width: 800px) {
    .footer-container {
      padding-bottom: 25px; } }
  .footer-container .footer-content {
    display: flex;
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 50px; }
    @media screen and (max-width: 800px) {
      .footer-container .footer-content {
        flex-direction: column; } }
    .footer-container .footer-content .footer-section.nav {
      flex: 0.7; }
      @media screen and (max-width: 800px) {
        .footer-container .footer-content .footer-section.nav {
          flex: 1; } }
    .footer-container .footer-content .footer-section.locations {
      flex: 1; }
      @media screen and (max-width: 800px) {
        .footer-container .footer-content .footer-section.locations {
          flex: 1;
          margin-top: 40px; } }
    .footer-container .footer-content .footer-section h3 {
      font-size: 18px;
      font-weight: normal;
      color: white;
      padding-bottom: 15px; }
      @media screen and (max-width: 800px) {
        .footer-container .footer-content .footer-section h3 {
          font-size: 18px; } }
    .footer-container .footer-content .footer-section ul {
      padding: 0;
      margin: 0;
      list-style-type: none; }
      .footer-container .footer-content .footer-section ul li {
        color: #9ac3c3;
        font-size: 18px;
        margin-bottom: 9px; }
        .footer-container .footer-content .footer-section ul li:hover {
          color: #eaf3f3; }
        @media screen and (max-width: 800px) {
          .footer-container .footer-content .footer-section ul li {
            margin-bottom: 2px;
            font-size: 16px; } }
    .footer-container .footer-content .footer-section .location-container {
      display: flex;
      background-color: #243e3d;
      padding-top: 0; }
      @media screen and (max-width: 800px) {
        .footer-container .footer-content .footer-section .location-container {
          flex-direction: column; } }
      .footer-container .footer-content .footer-section .location-container .location {
        flex: 1; }
        @media screen and (max-width: 800px) {
          .footer-container .footer-content .footer-section .location-container .location {
            margin-bottom: 40px; } }
        .footer-container .footer-content .footer-section .location-container .location h1 {
          font-family: "FredokaOne-Regular";
          color: white;
          font-weight: normal;
          font-size: 25px; }
          @media screen and (max-width: 800px) {
            .footer-container .footer-content .footer-section .location-container .location h1 {
              line-height: 38px; } }
        .footer-container .footer-content .footer-section .location-container .location h3 {
          padding: 0;
          margin: 0;
          font-size: 13px;
          line-height: 18px; }
        .footer-container .footer-content .footer-section .location-container .location p {
          color: #9ac3c3;
          padding: 0;
          margin: 0;
          font-size: 16px;
          line-height: 21px; }

.footer-bottom {
  background-color: #005352;
  float: left;
  width: 100%; }
  .footer-bottom .footer-bottom-container {
    max-width: 1440px;
    margin: 0 auto; }
  .footer-bottom img {
    width: 40px;
    float: left;
    margin-left: 50px; }
  .footer-bottom p {
    margin-left: 50px;
    float: left;
    color: white;
    font-size: 12px;
    line-height: 40px;
    padding: 0;
    margin-bottom: 0; }

.img-gallery-container {
  float: left;
  width: 100%; }

.about-container {
  float: left;
  width: 100%; }
  .about-container .ant-tabs-tabpane {
    width: 100%; }
  .about-container .ant-tabs-nav {
    margin: 0px 10px; }
    .about-container .ant-tabs-nav .ant-tabs-tab {
      font-size: 16px; }
    .about-container .ant-tabs-nav .ant-tabs-nav-list {
      margin: 0 auto; }
  .about-container .ant-tabs-content-holder {
    width: 100%;
    margin: 0 auto; }
    @media screen and (max-width: 1180px) {
      .about-container .ant-tabs-content-holder {
        padding: 50px 30px; } }
    @media screen and (min-width: 1180px) {
      .about-container .ant-tabs-content-holder {
        padding: 50px 100px;
        max-width: 2000px; } }
    @media screen and (max-width: 800px) {
      .about-container .ant-tabs-content-holder {
        padding: 20px; } }

.facebook-section-container {
  float: left;
  width: 100%;
  background-image: url("./img/collage.webp");
  background-repeat: no-repeat;
  background-size: cover; }
  .facebook-section-container .facebook-section-sub-container {
    float: left;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.88);
    padding-bottom: 60px; }
  .facebook-section-container .image {
    float: left;
    width: 100%; }
    .facebook-section-container .image img {
      width: 100%;
      float: left; }
  .facebook-section-container .location {
    width: 50%;
    float: left;
    padding: 50px; }
    .facebook-section-container .location.left {
      background-color: #006261; }
    .facebook-section-container .location.right {
      background-color: #bfd8d8; }

.first-time-container {
  float: left;
  width: 100%; }
  .first-time-container h2 {
    margin: 0;
    float: left;
    line-height: 59px; }
    @media screen and (max-width: 1180px) {
      .first-time-container h2 {
        font-size: 19px;
        line-height: 39px; } }
    @media screen and (max-width: 800px) {
      .first-time-container h2 {
        font-size: 17px;
        width: 100%;
        text-align: center; } }

.contact-form-container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  margin-bottom: 100px; }
  @media screen and (max-width: 800px) {
    .contact-form-container {
      flex-direction: column; } }
  .contact-form-container .ant-row.ant-form-item {
    margin: 0; }
  .contact-form-container .ant-input-affix-wrapper.ant-input-affix-wrapper-borderless {
    background-color: white;
    border-bottom: 1px solid #bfd8d8; }
  .contact-form-container .ant-input.ant-input-borderless {
    background-color: white; }
  .contact-form-container .ant-input-prefix {
    margin-right: 20px;
    color: #333 !important; }
  .contact-form-container .ant-input-affix-wrapper.ant-input-affix-wrapper-disabled.ant-input-affix-wrapper-borderless {
    cursor: default; }
    .contact-form-container .ant-input-affix-wrapper.ant-input-affix-wrapper-disabled.ant-input-affix-wrapper-borderless input {
      cursor: default;
      font-weight: bold;
      color: #333; }
      .contact-form-container .ant-input-affix-wrapper.ant-input-affix-wrapper-disabled.ant-input-affix-wrapper-borderless input ::placeholder {
        font-weight: bold; }
  .contact-form-container .ant-form-item-explain {
    padding: 0 10px;
    background-color: #ff4d4f;
    color: white; }
  .contact-form-container button {
    margin: 0;
    width: 100%;
    border-radius: 0; }
  .contact-form-container input {
    border-radius: 0;
    line-height: 50px; }
  .contact-form-container textarea {
    border-radius: 0;
    padding: 15px;
    height: 270px;
    min-height: 270px;
    max-height: 270px; }
  .contact-form-container ::placeholder {
    color: #616161; }
  .contact-form-container i {
    margin-right: 10px;
    color: #006261; }
  .contact-form-container .address-information-container {
    background-color: white;
    flex: 0.3;
    float: left;
    margin-left: 50px;
    min-width: 305px;
    margin-right: 20px; }
    @media screen and (max-width: 1180px) {
      .contact-form-container .address-information-container {
        padding: 50px 50px;
        height: 566px; } }
    @media screen and (min-width: 1180px) {
      .contact-form-container .address-information-container {
        padding: 80px 60px; } }
    @media screen and (max-width: 800px) {
      .contact-form-container .address-information-container {
        padding: 30px 30px;
        min-width: 200px;
        margin-bottom: 20px;
        margin-left: 35px;
        margin-right: 35px; } }
    .contact-form-container .address-information-container .location-text-section {
      margin-bottom: 20px;
      float: left;
      width: 100%; }
    .contact-form-container .address-information-container h1 i {
      margin-right: 15px; }
    @media screen and (max-width: 1180px) {
      .contact-form-container .address-information-container h1 {
        font-size: 20px;
        line-height: 35px; } }
    @media screen and (min-width: 1180px) {
      .contact-form-container .address-information-container h1 {
        font-size: 24px;
        line-height: 38px; } }
    @media screen and (max-width: 800px) {
      .contact-form-container .address-information-container h1 {
        font-size: 20px;
        line-height: 30px; } }
    .contact-form-container .address-information-container p {
      margin: 0; }
      @media screen and (max-width: 1180px) {
        .contact-form-container .address-information-container p {
          font-size: 15px;
          line-height: 20px; } }
      @media screen and (min-width: 1180px) {
        .contact-form-container .address-information-container p {
          font-size: 17px;
          line-height: 24px; } }
      @media screen and (max-width: 800px) {
        .contact-form-container .address-information-container p {
          line-height: 22px; } }
  .contact-form-container .form-container {
    flex: 0.7;
    position: relative;
    min-height: 546px;
    margin-right: 50px; }
    @media screen and (max-width: 1180px) {
      .contact-form-container .form-container {
        min-height: 600px; } }
    @media screen and (min-width: 1180px) {
      .contact-form-container .form-container {
        min-height: 566px; } }
    @media screen and (max-width: 800px) {
      .contact-form-container .form-container {
        min-height: 600px;
        margin-left: 35px;
        margin-right: 35px; } }
    .contact-form-container .form-container .form {
      position: absolute;
      width: 100%;
      height: 100%; }
    .contact-form-container .form-container .form-header {
      line-height: 50px; }
    .contact-form-container .form-container .ant-result {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      background-color: white; }
    .contact-form-container .form-container .form-button {
      height: 60px;
      background-color: #006261;
      border: 1px solid #006261;
      color: white; }
      .contact-form-container .form-container .form-button:hover {
        background-color: #004948;
        border: 1px solid #004948; }

.contact-screen-container .button-container {
  margin-top: 50px;
  margin-bottom: 50px; }
  .contact-screen-container .button-container button {
    margin-left: 0;
    margin-bottom: 0; }
